import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AdminGuard } from './auth/admin-guard'
import { WebsiteGuard } from './auth/website-guard'
import { PageNotFoundComponent } from './website/shared/page-not-found/page-not-found.component'
import { StudentGuard } from './auth/student-guard'
import { DeskManagerGuard } from './auth/desk-manager-guard'
import { InstructorGuard } from './auth/instructor-guard'
import { NoAuthGuard } from './auth/no-auth-guard'

const routes: Routes = [
    {
        path: 'admin',
        canActivateChild: [AdminGuard],
        loadChildren: () => import('./admin-panel/admin-panel.module').then((mod) => mod.AdminPanelModule)
    },
    {
        path: 'student',
        canActivateChild: [StudentGuard],
        loadChildren: () => import('./student-panel/student-panel.module').then((mod) => mod.StudentPanelModule)
    },
    {
        path: 'desk-manager',
        canActivateChild: [DeskManagerGuard],
        loadChildren: () => import('./desk-manager-panel/desk-manager-panel.module').then((mod) => mod.DeskManagerPanelModule)
    },
    {
        path: 'instructor',
        canActivateChild: [InstructorGuard],
        loadChildren: () => import('./instructor-panel/instructor-panel.module').then((mod) => mod.InstructorPanelModule)
    },
    {
        path: '',
        canActivateChild: [WebsiteGuard],
        loadChildren: () => import('./website/website.module').then((mod) => mod.WebsiteModule)
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { message: 'From ROOT' }
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutes {
    // code
}
