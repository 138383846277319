import { Event, Router } from '@angular/router'
import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    isLoading: boolean

    constructor(
        private route: Router,
        public ts: TranslateService
    ) {
        ts.addLangs(['en', 'no'])
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') as string : ts.getBrowserLang()
        ts.setDefaultLang(lang)
        ts.use(lang)

        this.route.events.subscribe((routerEvent: Event) => {
        })
    }
}
